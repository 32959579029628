import { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import Modal, { Props } from 'react-modal';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

import styled, { CSSProperties } from 'styled-components';

import { ButtonColor, ContainedButton, OutlinedButton } from './Button';
import { Heading4 } from './Typography';

Modal.setAppElement('#root');

const Header = styled.div`
  background: var(--black-20);
  min-height: 56px;
  padding: 0 16px;
  display: flex;
  text-transform: uppercase;

  svg:hover {
    cursor: pointer;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  justify-content: center;
  flex: 1;
`;

const Footer = styled.div`
  height: 80px;
  padding: 16px;
  box-sizing: border-box;
`;

export const ModalMessageContainer = styled.div`
  text-align: center;
  padding: 32px 0;
`;

export interface ModalProps extends Props {
  header: ReactNode;
  type?: 'message' | 'action' | 'custom';
  isEditTeam?: boolean;
  submitAction?: {
    title: string;
    onSubmit: () => void;
    buttonColor?: ButtonColor;
    disabled?: boolean;
  };
  footerStyle?: CSSProperties;
  submitOnEnter?: boolean;
}

export const CustomModal: FC<ModalProps> = ({
  children,
  header,
  type = 'action',
  isEditTeam = false,
  submitAction,
  style,
  footerStyle,
  submitOnEnter,
  ...props
}) => {
  useEffect(() => {
    if (
      props.isOpen &&
      submitOnEnter &&
      submitAction &&
      !submitAction.disabled
    ) {
      const handlekeyup = function (e: KeyboardEvent) {
        if (e.key === 'Enter') {
          submitAction.onSubmit();
        }
      };
      setTimeout(() => {
        const modalEl = document.querySelector('.ReactModal__Overlay');
        modalEl?.addEventListener('keyup', handlekeyup);
      }, 500);

      return () => {
        const modalEl = document.querySelector('.ReactModal__Overlay');
        modalEl?.removeEventListener('keyup', handlekeyup);
      };
    }
  }, [props.isOpen]);

  return (
    <Modal
      {...props}
      style={{
        overlay: {
          background: 'rgba(0, 0, 0, 0.7)',
          ...style?.overlay,
        },
        content: {
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          height: 'fit-content',
          background:
            'radial-gradient(108.22% 143.2% at 23.89% 0%, #005FCE 0%, #16367C 100%)',
          borderRadius: 16,
          border: 'none',
          padding: 0,
          display: 'flex',
          flexDirection: 'column',
          maxHeight: '100vh',
          ...style?.content,
        },
      }}
    >
      {header ? (
        <Header className='flex-between'>
          <Heading4>{header}</Heading4>
          <CloseIcon onClick={props.onRequestClose} />
        </Header>
      ) : null}

      <Content
        // onKeyUp={handlekeyup}
        style={{
          textAlign: type === 'message' ? 'center' : undefined,
          flexGrow: isEditTeam ? 1 : undefined,
          overflow: isEditTeam ? 'auto' : undefined,
          display: isEditTeam ? 'block' : undefined,
        }}
      >
        {children}
      </Content>

      {type === 'custom' ? null : type === 'action' ? (
        <Footer className='flex-between' style={footerStyle}>
          <OutlinedButton
            size='md'
            onClick={props.onRequestClose}
            style={{ width: !isEditTeam ? 176 : '50%', marginRight: 16 }}
          >
            Cancel
          </OutlinedButton>
          {submitAction ? (
            <ContainedButton
              size='md'
              containedColor={submitAction?.buttonColor ?? 'danger'}
              onClick={submitAction.onSubmit || props.onRequestClose}
              style={{
                width: !isEditTeam ? 176 : '50%',
                backgroundImage: isEditTeam ? 'none' : undefined,
                background: isEditTeam ? 'var(--success)' : undefined,
                backgroundSize: '100% 48px',
              }}
              disabled={submitAction.disabled}
            >
              {submitAction.title || 'Delete'}
            </ContainedButton>
          ) : null}
        </Footer>
      ) : (
        <Footer className='flex-between' style={footerStyle}>
          <ContainedButton
            size='xl'
            onClick={props.onRequestClose}
            style={{ width: '100%' }}
          >
            OK
          </ContainedButton>
        </Footer>
      )}
    </Modal>
  );
};

export const useModal = () => {
  const [open, setOpen] = useState(false);

  const renderModal = useCallback(
    (props: Omit<ModalProps, 'isOpen'>) => (
      <CustomModal
        {...props}
        isOpen={open}
        shouldCloseOnOverlayClick={
          props.shouldCloseOnOverlayClick !== undefined
            ? props.shouldCloseOnOverlayClick
            : true
        }
        onRequestClose={() => setOpen(false)}
      />
    ),
    [open],
  );

  return {
    Modal: renderModal,
    openModal: () => setOpen(true),
    closeModal: () => setOpen(false),
    setOpenModal: setOpen,
    open,
  };
};
