import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { PATH } from 'helpers/constant';
import { useGetAssignHostsData } from 'pages/hooks';

import store, { useAppSelector } from 'store';
import { AdaptedRoles } from 'store/authSlice';
import { finishHostsAssignment } from 'store/gameSlice';

import { PageContent, PageLayout, PageNavBar } from 'components/Layout';
import { Heading2 } from 'components/Typography';
import AssignHostsRoom, {
  roles,
} from 'components/assign-hosts-room/AssignHostsRoom';
import { resetBracketReveal } from 'components/playoff-bracket/playoffBracketSlice';
import styled from 'styled-components';

import { ContinueButton } from './BreakoutRoomPage';
import { updateHostsAssignment, updateRoomHost } from 'api/game';

export interface TeamsListUpdate {
  index: number;
  name: string;
  teamPhoto: string | null;
  members: any[];
  initialMembers: any[];
}

export const AssignHostsPage = () => {
  const navigate = useNavigate();
  const { game } = useAppSelector((state) => state.game);
  const role = useAppSelector((state) => state.auth.role);

  const { id, teamNumber } = game || {};
  const { hosts, rooms, setRooms } = useGetAssignHostsData(game.id, teamNumber);
  const disabledSubmit = useMemo(
    () => !rooms.length || rooms.some((room) => !room.host || !room.coHost),
    [rooms],
  );

  const isGameLeader = useMemo(
    () => role === AdaptedRoles[roles.GAMELEADER],
    [role],
  );

  useEffect(() => {
    if (game.isHostsAssigned) {
      navigate(PATH.breakoutRoom);
    }
  }, [game.isHostsAssigned]);

  useEffect(() => {
    store.dispatch(resetBracketReveal());
  }, []);

  const handleFinishHostsAssignment = useCallback(() => {
    updateHostsAssignment(id, () => store.dispatch(finishHostsAssignment()));
  }, []);

  const handleChangeHost = useCallback(
    async ({
      newHost,
      roomId,
      hostType,
    }: {
      newHost: string | null;
      roomId: string;
      hostType: roles;
    }) => {
      const hostUserInfo = newHost
        ? hosts.find((host) => host.host.id === newHost)?.host || {
            id: newHost,
            email: '0',
            firstName: '0',
            lastName: '0',
            role: '0',
          }
        : null; // create empty co-host data if user select None option

      await updateRoomHost({ roomId, host: hostUserInfo, role: hostType });

      setRooms((prev) =>
        [...prev].map((room) =>
          room.id === roomId
            ? {
                ...room,
                [hostType]: hostUserInfo,
              }
            : room,
        ),
      );
    },
    [hosts],
  );

  return (
    <PageLayout>
      <PageNavBar
        LeftContent={<Heading2>Host Assignments</Heading2>}
        MenuContent={<></>}
      />

      <PageContent
        style={{
          alignItems: 'flex-start',
          maxHeight: 'calc(100vh - 8.5rem)',
          overflow: 'hidden',
        }}
      >
        {game && (
          <TeamNameInputForm rowsCount={rooms.length / 2}>
            {rooms.map((room, _, roomsList) => (
              <AssignHostsRoom
                key={room.id}
                roomInfo={room}
                hosts={hosts}
                rooms={roomsList}
                changeHost={handleChangeHost}
              />
            ))}
          </TeamNameInputForm>
        )}
      </PageContent>

      {isGameLeader && (
        <ContinueButton
          onClick={handleFinishHostsAssignment}
          disabled={disabledSubmit}
        >
          Continue
        </ContinueButton>
      )}
    </PageLayout>
  );
};

const TeamNameInputForm = styled.div<{ rowsCount: number }>`
  display: grid;
  grid-auto-rows: minmax(133px, max-content);
  // grid-template-rows: repeat(${({ rowsCount }) => rowsCount}, auto);
  // grid-template-rows: repeat(${({ rowsCount }) =>
    Math.max(rowsCount, 4)}, 1fr);
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 16px;
  grid-column-gap: 32px;
  margin-top: 36px;
  grid-auto-flow: row;
  min-height: calc(100% - 36px);
  height: fit-content;
  overflow: auto;
  width: 100%;
`;
