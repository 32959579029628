import axios, { AxiosResponse } from 'axios';

import { toast } from 'react-toastify';

import store from 'store';
import { setGameSlice } from 'store/gameSlice';
import { EndGameStatus, GameStatus } from 'store/gameplaySlice';
import { closeLoading, openLoading } from 'store/loadingSlice';
import { ResourceCategoryType } from 'store/resources';

import {
  hostStatuses,
  roles,
} from 'components/assign-hosts-room/AssignHostsRoom';
import { Tournament } from 'components/playoff-bracket/playoffBracketSlice';

import { HostItem } from './default-settings';
import { uploadFile } from './file';

export interface GameInfo {
  id: string;
  startTime: string;
  gameType: string;
  breakoutTimer: number;
  teamNumber: number;
  customer: {
    company: string;
    contactPerson: string;
    partner: string;
  };
  code: string;
  hosts: {
    code: string;
    host: Partial<HostItem>;
  }[];
  expireTime: string;
  timeZone: number;
  status: GameStatus;
  statusEnd: EndGameStatus;
  questionsPerMatchup: number;
  gameTimer: number;
  isPlaying: boolean;
  player: number;
  isBreakout: number;
  isHostsAssigned: boolean;
  isBreakoutPopup: number;
  notStarted?: number;
  theme: {
    id: number;
    type: string;
    name: string;
    value: string;
  };
}

export const getDetailGame = (
  id: string,
  callback: (data: GameInfo) => void,
  onError?: () => void,
) => {
  axios
    .get(`/api/games/${id}`)
    .then((res: AxiosResponse<{ data: GameInfo }>) => {
      callback(res.data.data);
    })
    .catch((err) => {
      onError?.();
      console.log('getDetailGame err', err);
      toast.error(err.message || 'Game not found');
    });
};

export const getDetailGameForHost = (
  { eventId, roomId }: { eventId: string; roomId?: string },
  callback?: (id?: string) => void,
  onError?: () => void,
) => {
  const url = roomId
    ? `/host/profile?eventId=${eventId}&roomId=${roomId}`
    : `/host/profile?eventId=${eventId}`;

  axios
    .get(url)
    .then(
      (
        res: AxiosResponse<{
          data: {
            index: number;
            game: GameInfo;
            isPlaying: boolean;
            gameRoom: any;
            id: string;
          };
        }>,
      ) => {
        const {
          index,
          game,
          isPlaying,
          gameRoom,
          id: currentUserId,
        } = res.data.data;

        store.dispatch(
          setGameSlice({
            index,
            currentUserId,
            game: { ...game, isPlaying },
            gameRoom: gameRoom
              ? { isStart: gameRoom.isStart, id: gameRoom.id }
              : {},
          }),
        );
        callback?.(gameRoom?.id);
      },
    )
    .catch((err) => {
      onError?.();
      console.log('getDetailGameForHost err', err);
    });
};

interface TeamName {
  id: number;
  index: number;
  name: string;
  members: Array<any>;
  gameHost?: {
    game?: GameInfo;
  };
  game: GameInfo & { gameOld?: any };
}

export const getTeamsList = (
  id: string,
  callback: (data: Array<TeamName>) => void,
) => {
  axios
    .get('/api/game-teams', {
      params: {
        page: 1,
        limit: 999,
        gameId: id,
      },
    })
    .then((res: AxiosResponse<{ data: { items: Array<TeamName> } }>) => {
      callback(res.data.data.items);
    })
    .catch((err) => {
      console.log('getTeamsList err', err);
    });
};

export const getRoomsList = async (
  id: string,
  callback: (data: any) => void,
) => {
  await axios
    .get('/api/game-rooms/get-event-rooms', {
      params: {
        eventId: id,
        orderBy: 'index',
        orderDirection: 'ASC',
      },
    })
    .then((res: AxiosResponse<{ data: any }>) => {
      callback(res.data.data);
    })
    .catch((err) => {
      console.log('getRoomsList err', err);
    });
};

export const getAvailableHosts = async (
  id: string,
  callback: (data: any) => void,
) => {
  await axios
    .get(`/api/games/${id}/hosts`, {
      params: {
        gameId: id,
      },
    })
    .then((res: AxiosResponse<{ data: any }>) => {
      callback(res.data.data);
    })
    .catch((err) => {
      console.log('getHostsList err', err);
    });
};

export const updateUserActualStatus = (
  data: {
    eventId: string;
    userId: string;
    role: roles;
  },
  callback?: (data: any) => void,
) => {
  axios
    .patch('/api/hosts/update-actual-status', data)
    .then((res: AxiosResponse<{ data: any }>) => {
      callback?.(res.data.data);
    })
    .catch((err) => {
      console.log('getTeamsList err', err);
    });
};

export interface hostUserInfo {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  role: string;
}

export interface fullHostInfo {
  id: string;
  role: roles;
  status: hostStatuses;
  host: hostUserInfo;
}

export const updateRoomHost = async ({
  roomId,
  host,
  role,
}: {
  roomId: string;
  host: hostUserInfo | null;
  role: roles;
}) => {
  await axios
    .patch(`/api/game-rooms/${roomId}/update-host`, { host, role })
    .then((res: AxiosResponse<{ data: any }>) => {
      return res.data.data;
    })
    .catch((err) => {
      console.log('UpdateTeamHost err', err);
    });
};

export const createTeam = (
  id: string,
  body: {
    index: number;
    name: string;
  },
  callback: () => void,
  onError: () => void,
) => {
  axios
    .post(`/api/game-teams`, body)
    .then(() => {
      callback();
    })
    .catch((err) => {
      onError();
      console.log('createTeam err', err);
      toast.error(err.message || 'Create team failed');
    });
};

export const getTournaments = (
  id: string,
  callback: (data: Tournament[]) => void,
) => {
  axios
    .get(`/api/gameplay/${id}/tournament`)
    .then((res: AxiosResponse<{ data: Tournament[] }>) => {
      callback(res.data.data);
    });
};

export const getTournamentsForAdmin = (
  id: string,
  callback: (data: Tournament[]) => void,
) => {
  axios
    .get(`/api/admin/gameplay/${id}/tournament`)
    .then((res: AxiosResponse<{ data: Tournament[] }>) => {
      callback(res.data.data);
    });
};

export const createTeamForAdmin = (
  id: string,
  body: {
    index: number;
    name: string;
    teamPhoto: string | null;
    members: any[];
    initialMembers: any[];
  },
  callback: (data: any) => void,
  onError: () => void,
) => {
  axios
    .post(`/api/game-teams/game/${id}`, body)
    .then((res) => {
      const { resultMembersList } = res.data.data;
      callback(resultMembersList);
      // callback(updatedMembers);
    })
    .catch((err) => {
      onError();
      console.log('createTeamForAdmin err', err);
      toast.error(err.message || 'Create team for admin failed');
    });
};

export const createInitialTeamsForAdmin = async (
  id: string,
  body: Array<{
    id: string;
    index: number;
    name: string;
  }>,
  callback?: () => void,
) => {
  await axios
    .post(`/api/game-teams/game/${id}/create-initial-teams`, body)
    .catch((err) => {
      console.log('createInitialTeamsForAdmin err', err);
      toast.error(err.message || 'Create initial teams for admin failed');
    })
    .finally(() => callback?.());
};

export const updateHostsAssignment = async (
  id: string,
  callback: () => void,
) => {
  openLoading();
  await axios
    .patch(`/api/games/${id}/hosts-assignment`)
    .then(() => {
      closeLoading();
      callback();
    })
    .catch((err) => {
      closeLoading();
      console.log('finishHostsAssignment err', err);
    });
};

export const updateGameStatus = (
  id: string,
  status: GameStatus,
  callback: () => void,
) => {
  openLoading();
  axios
    .patch(`/api/games/${id}/status`, { status })
    .then(() => {
      closeLoading();
      callback();
    })
    .catch((err) => {
      closeLoading();
      console.log('updateGameStatus err', err);
    });
};

export const updateEndGameStatus = (
  id: string,
  statusEnd: EndGameStatus,
  callback?: () => void,
) => {
  openLoading();
  axios
    .patch(`/api/games/${id}/status-end`, { statusEnd })
    .then(() => {
      closeLoading();
      callback?.();
    })
    .catch((err) => {
      closeLoading();
      console.log('updateGameStatus err', err);
    });
};

export const updateTeamPhoto = async (
  id: any,
  body: Partial<{
    teamPhoto: string;
  }>,
  callback: () => void,
) => {
  const { teamPhoto } = body;
  const formData = new FormData();
  if (teamPhoto) {
    formData.append('teamPhoto', teamPhoto);
  }

  openLoading();
  try {
    const fileId = await uploadFile(teamPhoto);
    await axios.patch(`/api/games/upload/team-photo/${id}`, {
      fileId,
    });

    closeLoading();
    callback();
    toast.success('Team Photo Uploaded');
  } catch (err) {
    closeLoading();
    console.log('upload eror err', err);
    toast.error(err.message || 'Team Photo Upload failed');
  }
};

export const getResources = ({
  callback,
  onError,
}: {
  callback: (data: Array<ResourceCategoryType>) => void;
  onError?: () => void;
}) => {
  axios
    .get(`/api/resources`)
    .then((res: any) => {
      callback(res.data.data?.resource?.categories || []);
    })
    .catch((err) => {
      onError?.();
      console.log('getResources err', err);
      toast.error(err.message || 'Resources not found');
    });
};
