import { Config } from 'config';

import * as Sentry from '@sentry/react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import initialInterceptor from 'helpers/axios-init';
import { requestUrls } from 'helpers/constant';

import store, { persistor } from './store';

import { CustomToast } from 'components/Toast';

import App from './App';
import './index.css';

const regexPattern = new RegExp(
  `^${Config.REACT_APP_LAUNCHPAD_BASE_URL.replace(
    /\//g,
    '\\/',
  )}((?!${requestUrls.storageUrl.replace(/\//g, '\\/')}).)*$`,
);
Sentry.init({
  dsn: Config.REACT_APP_FEUD_FE_SENTRY_DSN,
  integrations: [
    Sentry.replayIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  normalizeDepth: 10,
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    // exclude /storage/files requests using regexp
    regexPattern,
    Config.REACT_APP_API_URL,
    Config.REACT_APP_WEB_SOCKET_URL,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

initialInterceptor();

const container = document.getElementById('root');

/* eslint-disable @typescript-eslint/no-non-null-assertion*/
const root = createRoot(container!);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
      <CustomToast />
    </PersistGate>
  </Provider>,
);
