import uuidv4 from 'uuidv4';

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ReactComponent as BigStrikeIcon } from 'assets/icons/strike-big.svg';
import { ReactComponent as SmallStrikeIcon } from 'assets/icons/strike-small.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
// import RevealAnswerItemBackground from 'assets/images/gameplay/answer-item-reveal.png';
// import AnswerItemBackground from 'assets/images/gameplay/answer-item.png';
import GameBoardBackground from 'assets/images/gameplay/game-board-bg.png';
import Background from 'assets/images/gameplay/gameplay-bg.png';
// import { ReactComponent as NumberTagIcon } from 'assets/images/gameplay/number-tag.svg';
import QuestionBackground from 'assets/images/gameplay/question-bg.png';
import ScoreBoardBackground from 'assets/images/gameplay/score-board-bg.png';
import LogoImage from 'assets/images/logo.png';

import { PATH } from 'helpers/constant';
import { usePlaySound } from 'helpers/utils';
import { isNumber, sumBy } from 'lodash';
import { useGetGamePlayData } from 'pages/hooks';

import store, { useAppSelector } from 'store';
import {
  RoundStatus,
  curRoundNumberSelector,
  curRoundStatusSelector,
  resetCurRoundAnswer,
  roundFinishedSelector,
  strikeCountSelector,
} from 'store/gameplaySlice';

import { ContainedButton, OutlinedButton } from 'components/Button';
import { useModal } from 'components/Modal';
import { Display1, Display2, Heading4 } from 'components/Typography';
import FlipCardItem from 'components/flip-card/FlipCard';
import styled from 'styled-components';

import { PlayingTeamDrawer } from './TeamView';
import { getDetailGameForHost } from 'api/game';
import {
  finishGameRoom,
  getGamePlayingInfo,
  getGameRoundInfo,
  revealQuestionAnswer,
  startGameRound,
  strikeForTeam,
  throwOutQuestion,
  updateRoundStatusPlaying,
} from 'api/gameplay';

const Container = styled.div`
  position: relative;
  min-height: 100%;
  width: 100%;
  background-image: url(${Background});
`;
const ScoreBoard = styled.div`
  position: fixed;
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
  background-image: url(${ScoreBoardBackground});
  background-size: 100% 100%;
  width: 28vh;
  height: 16vh;
  user-select: none;
`;
const GameBoard = styled.div`
  background-image: url(${GameBoardBackground});
  background-size: 100% 100%;
  position: fixed;
  top: 53%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(60vw + 16px);
  height: calc(56vh + 96px);
`;
const QuestionBoard = styled.div`
  background-image: url(${QuestionBackground});
  background-size: cover;
  position: fixed;
  width: calc(100% - 2vw + 6px);
  height: calc(100% - 24px);
  margin: 12px calc(1vw - 3px);
  box-sizing: border-box;
  background-blend-mode: color-burn, overlay, normal;
  /* box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.4),
    inset 4px 4px 4px rgba(0, 0, 0, 0.25); */
  border-radius: 20px;
  padding: 32px;
  text-align: center;
  transition: all ease 0.5s;
`;
const AnswerBoard = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(4, 14vh);
  grid-auto-flow: column;
  grid-gap: 16px;
  padding: 24px;
  box-sizing: border-box;
`;

const StrikesPopup = styled.div<{ show?: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: ${({ show }) => (show ? 1 : 0)};
  height: ${({ show }) => (show ? '357px' : 0)};
  overflow: hidden;
  transition: height 0.2s ease-in-out, opacity 0.2s ease-in-out;
  display: flex;

  & > svg {
    position: relative;
    height: 100%;
    top: 50%;
    transform: translate(0, -50%);
  }
`;

const GameFooter = styled.div`
  position: fixed;
  z-index: 2;
  bottom: 0;
  left: 0;
  right: 0;
  height: 10vh;
  background: #000;
  padding: 0 40px;
`;
const FooterCenter = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  > *:not(:last-child) {
    margin-right: 32px;
  }
`;
const AnswerCD = styled.div<{ disabled: boolean }>`
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid var(--white-10);
  border-radius: 16px 16px 0px 0px;
  align-self: flex-end;
  width: 160px;
  height: 80%;
  user-select: none;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`;
const Strikes = styled.div<{ disabled: boolean }>`
  display: flex;
  position: relative;
  &::before {
    display: ${({ disabled }) => (disabled ? 'block' : 'none')};
    content: "''";
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: default;
    z-index: 1;
  }
  svg {
    margin-left: 16px;
    &.disabled {
      cursor: pointer;
      path {
        fill: #fff;
        fill-opacity: 0.4;
      }
    }
  }
`;
const NextRoundButton = styled(ContainedButton)`
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 16px;
  padding: 0 16px 4px 16px;
  width: auto;
  background-size: 100% 100%;
`;

const INTRO_TIMEOUT = 2100;
const maxAnswersNumber = 8;

export type AnswerType = {
  id?: string;
  answer: string;
  point: number;
  isEmpty?: boolean;
};

export const GamePlayPage = () => {
  const { roomId } = useParams();
  const navigate = useNavigate();
  const { openModal, Modal, closeModal } = useModal();

  // for adding point multiplier on last question of the matchup
  const answerTimer = useAppSelector(
    (state) => state.gameplay.game.answerTimer,
  ); // seconds
  const { question, answers } = useAppSelector(
    (state) => state.gameplay.curRound,
  );

  const round = useAppSelector(curRoundNumberSelector);
  const tieScore = useAppSelector(
    (state) => state.gameplay.pointA === state.gameplay.pointB,
  );
  const roundStatus = useAppSelector(curRoundStatusSelector);
  const strikeCountStoreState = useAppSelector(strikeCountSelector);
  const { roundFinished, matchUpFinished } = useAppSelector(
    roundFinishedSelector,
  );
  const location = useLocation();
  const isOnlyViewAccess = useMemo(() => !!(location.state as any)?.host, []);
  const gameId = useAppSelector((state) => state.game.game.id);
  const room = useAppSelector((state) => state.game.gameRoom);
  const [started, setStarted] = useState(false);
  const [showQuestion, setShowQuestion] = useState(false);
  const [revealAnswers, setRevealAnswers] = useState<string[]>([]);
  const [timeCD, setTimeCd] = useState<number>(answerTimer);
  const [strikeCount, setStrikeCount] = useState<number>(0);
  const [displayAnswers, setDisplayAnswer] = useState<number>(0);
  const [showStrikePopup, setShowStrikePopup] = useState(false);
  const itv = useRef<any>(null);
  const [tmp, setTmp] = useState<number>();

  const correctSound = usePlaySound('correct');
  const strikeSound = usePlaySound('strike');
  const introSound = usePlaySound('intro');
  const answerIntroSound = usePlaySound('answerIntro');
  const [isFlipping, setIsFlipping] = useState(false);
  const [isGameInfoLoaded, setIsGameInfoLoaded] = useState(false);
  /*
  Disabled Task
  const isFlipping = useRef(false);
  */

  const rountPoint = answers
    ? sumBy(
        question.answers.filter((el) => answers.includes(el.id.toString())),
        'point',
      )
    : 0;

  useEffect(() => {
    getDetailGameForHost(
      { eventId: gameId },
      () => {
        setIsGameInfoLoaded(true);
      },
      () => navigate(`/game/${gameId}/panel`),
    );
  }, []);

  const fetchGameInfo = useCallback(() => {
    //check if prev use effect request is finished
    if (isGameInfoLoaded) {
      if (room?.id) {
        getGamePlayingInfo(
          {
            eventId: gameId,
            roomId,
          },
          getGameRoundInfo,
        );
      }
    }
  }, [gameId, room?.id, isGameInfoLoaded]);

  useGetGamePlayData(isGameInfoLoaded, roomId, gameId);

  useEffect(() => {
    if (
      isNumber(roundStatus) &&
      roundStatus !== RoundStatus.ready &&
      started === false &&
      question.answers.length > 0
    ) {
      setStarted(true);
      setDisplayAnswer(question.answers.length + 1);
    }
    if (
      started === false &&
      roundStatus === RoundStatus.ready &&
      question.answers.length
    ) {
      introSound.play();

      setTimeout(() => {
        setStarted(true);
        updateRoundStatusPlaying({ gameId, roomId });
        const interval = setInterval(
          () =>
            setDisplayAnswer((prev) => {
              answerIntroSound.play();
              if (prev === question.answers.length) {
                clearInterval(interval);
              }
              return prev <= question.answers.length ? prev + 1 : 0;
            }),
          200,
        );
      }, INTRO_TIMEOUT);
    }
  }, [started, question.answers.length, roundStatus]);

  useEffect(() => {
    if (isOnlyViewAccess && round !== 1) {
      setDisplayAnswer(question.answers.length + 1);
    }
  }, [question.answers.length, round]);

  useEffect(() => {
    if (roundFinished) {
      setShowQuestion(false);
    }
  }, [roundFinished]);

  useEffect(() => {
    fetchGameInfo();
  }, [fetchGameInfo]);

  useEffect(() => {
    if (showStrikePopup) {
      setTimeout(() => setShowStrikePopup(false), 1300);
    }
  }, [showStrikePopup]);

  useEffect(() => {
    Number.isFinite(strikeCountStoreState) &&
      setStrikeCount(strikeCountStoreState);
  }, [strikeCountStoreState]);

  useEffect(() => {
    setTimeCd(answerTimer);
  }, [answerTimer]);

  useEffect(() => {
    const answerIds: string[] = answers?.split(',') || [];
    setRevealAnswers(answerIds);
  }, [answers]);

  const resetClock = () => {
    clearInterval(itv.current);
    itv.current = null;
    setTimeCd(answerTimer);
  };

  const startCountdown = () => {
    if (isOnlyViewAccess) return;
    if (started === false) {
      return toast.error('Show the question first!');
    }
    if (itv.current === null) {
      itv.current = setInterval(() => {
        setTimeCd((prev) => {
          if (prev === 1) {
            clearInterval(itv.current);
            onClickStrike('strike')();
          }
          return prev > 0 ? prev - 1 : 0;
        });
      }, 1000);
    } else {
      resetClock();
    }
  };

  const onClickStrike = useCallback(
    (strike: number | 'strike') => () => {
      if (isOnlyViewAccess) return;

      resetClock();

      const isCurStriked = strikeCount > +strike;

      const strikeCounter =
        strike === 'strike'
          ? strikeCountStoreState + 1
          : isCurStriked
          ? strike
          : strikeCountStoreState + 1;

      if (strikeCounter > 3 || strikeCounter < 0) {
        return;
      }

      strikeForTeam(strikeCounter, () => {
        setStrikeCount(() => {
          if (isCurStriked === false) {
            strikeSound.play();
            setShowStrikePopup(true);
          }

          return strikeCounter;
        });
      });
    },
    [
      isOnlyViewAccess,
      resetClock,
      strikeCount,
      strikeCountStoreState,
      strikeForTeam,
      setStrikeCount,
    ],
  );

  const resetAllState = () => {
    store.dispatch(resetCurRoundAnswer());
    setRevealAnswers([]);
    setDisplayAnswer(0);
    setStarted(false);
    setShowQuestion(false);
    setStrikeCount(0);
    closeModal();
  };

  const allowFlipping = useCallback(() => {
    setIsFlipping(false);
    setTimeout(() => {
      correctSound.play();
    }, 500);
  }, []);

  const handleClickAnswer = useCallback(
    (index: number) => {
      if (isOnlyViewAccess) return;

      if (
        index < question.answers.length &&
        !revealAnswers.includes(question.answers[index].id)
      ) {
        resetClock();
        if (isFlipping) return;
        setIsFlipping(true);
        revealQuestionAnswer(
          {
            answerId: question.answers[index].id,
            point: roundFinished ? 0 : question.answers[index].point,
          },
          allowFlipping,
        );
      }
    },
    [
      isOnlyViewAccess,
      question,
      resetClock,
      isFlipping,
      gameId,
      roomId,
      roundFinished,
      allowFlipping,
      revealAnswers,
    ],
  );

  useEffect(() => {
    function keyHandler(e: KeyboardEvent) {
      if (+e.key > 0 && +e.key <= question.answers.length) {
        handleClickAnswer(+e.key - 1);
      } else if (
        (e.key === 'x' || e.key === 'X') &&
        strikeCount < 3 &&
        !roundFinished
      ) {
        onClickStrike(strikeCount)();
      }
    }
    if (displayAnswers > question.answers.length) {
      window.addEventListener('keydown', keyHandler);

      return () => {
        window.removeEventListener('keydown', keyHandler);
      };
    }
  }, [
    question.answers.length,
    displayAnswers,
    strikeCount,
    handleClickAnswer,
    onClickStrike,
    roundFinished,
  ]);

  const answersFullList: Array<AnswerType> = useMemo(() => {
    const answersList: Array<AnswerType> = [...question.answers];

    for (let i = question.answers.length; i < maxAnswersNumber; i++) {
      answersList.push({
        id: uuidv4(),
        answer: '',
        point: 0,
        isEmpty: true,
      });
    }

    return answersList;
  }, [question.answers]);

  const renderGameBoardContent = () => {
    return (
      <>
        <QuestionBoard
          className='flex-center bg-center'
          style={{
            opacity: showQuestion ? 1 : 0,
            visibility: started ? 'visible' : 'hidden',
            zIndex: showQuestion ? 9 : 0,
          }}
        >
          <Display1>{question.content}</Display1>
        </QuestionBoard>
        <QuestionBoard
          className='flex-center bg-center'
          style={{
            transform: started ? 'scaleY(0)' : 'scaleY(1)',
            zIndex: started ? 0 : 9,
            visibility: started ? 'hidden' : 'visible',
          }}
        >
          <img src={LogoImage} alt='logo' id='logo' style={{ width: '50%' }} />
        </QuestionBoard>
        <AnswerBoard
          className='flex-center bg-center'
          style={{
            visibility: started ? 'visible' : 'hidden',
          }}
        >
          {answersFullList.map((answer, index) => {
            return (
              <FlipCardItem
                key={answer.id}
                // question={question}
                answer={answer}
                index={index}
                revealAnswers={revealAnswers}
                displayAnswers={displayAnswers}
                handleClickAnswer={handleClickAnswer}
              />
            );
          })}
        </AnswerBoard>
      </>
    );
  };

  return (
    <Container className='bg-center'>
      <PlayingTeamDrawer tmp={tmp} roundPoint={rountPoint} />

      <ScoreBoard className='flex-center bg-center'>
        <Display1>
          {rountPoint || '00'}
          {/* {lastQuestionForMatchup ? ` X ${pointsMultiplier}` : "" } */}
        </Display1>
      </ScoreBoard>

      <GameBoard className='bg-center'>{renderGameBoardContent()}</GameBoard>

      {roundFinished ? (
        // (isOnlyViewAccess) ||
        revealAnswers.length < question.answers.length ? null : (
          <NextRoundButton
            containedColor='success'
            onClick={() => {
              if (isOnlyViewAccess) {
                navigate(`/game/${gameId}/panel`);
                return;
              }
              if (!matchUpFinished) {
                return startGameRound(
                  {
                    gameId,
                    roomId,
                  },
                  () => {
                    setTmp(Date.now());
                    resetAllState();
                    getGamePlayingInfo(
                      {
                        eventId: gameId,
                        roomId,
                      },
                      getGameRoundInfo,
                    );
                  },
                );
              }
              if (tieScore) {
                return toast.warning('Tie score');
              }
              finishGameRoom({ roomId }, () => {
                navigate(PATH.scoreSummaryWaiting);
                setTmp(Date.now());
                resetAllState();
              });
            }}
            size='md'
          >
            {matchUpFinished ? 'End Matchup' : `Proceed to Round ${round + 1}`}
          </NextRoundButton>
        )
      ) : (
        <GameFooter className='flex-between'>
          {started ? (
            <TrashIcon
              width={40}
              height={40}
              onClick={() => {
                if (isOnlyViewAccess) return;
                openModal();
              }}
              style={{ cursor: 'pointer' }}
            />
          ) : null}
          <FooterCenter>
            <OutlinedButton
              onClick={() => {
                setShowQuestion((prev) => !prev);
              }}
              style={{ height: '80%' }}
              disabled={isOnlyViewAccess}
            >
              {showQuestion ? 'Hide question' : 'Show question'}
            </OutlinedButton>
            <AnswerCD
              className='flex-center'
              onClick={() => {
                if (!isOnlyViewAccess) {
                  setTimeCd(answerTimer);
                  startCountdown();
                }
              }}
              disabled={isOnlyViewAccess}
            >
              <Display2 color='primary'>{timeCD}</Display2>
            </AnswerCD>
            <OutlinedButton
              style={{ opacity: 0, pointerEvents: 'none' }}
              disabled={isOnlyViewAccess}
              // onClick={() => {
              //   if (isAdmin || started === false) return;
              //   setShowQuestion(false);
              //   store.dispatch(setShowAddRoundPoint());
              // }}
              // disabled={started === false || isAdmin || teamPlay === null}
            >
              Add round point
            </OutlinedButton>
          </FooterCenter>

          {started && !roundFinished ? (
            <Strikes disabled={isOnlyViewAccess}>
              <SmallStrikeIcon
                className={strikeCount > 0 ? '' : 'disabled'}
                onClick={onClickStrike(0)}
              />
              <SmallStrikeIcon
                className={strikeCount > 1 ? '' : 'disabled'}
                onClick={onClickStrike(1)}
              />
              <SmallStrikeIcon
                className={strikeCount > 2 ? '' : 'disabled'}
                onClick={onClickStrike(2)}
              />
            </Strikes>
          ) : (
            <div />
          )}
        </GameFooter>
      )}

      <StrikesPopup show={showStrikePopup}>
        {new Array(strikeCount).fill(null).map((_v, index) => (
          <BigStrikeIcon key={index} />
        ))}
      </StrikesPopup>

      <Modal
        header={'THROW OUT QUESTION?'}
        submitAction={{
          title: 'THROW OUT',
          onSubmit: () => {
            throwOutQuestion(resetAllState);
          },
        }}
        submitOnEnter
      >
        <div style={{ padding: '20px 0' }}>
          <Heading4 style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            Are you sure to THROW THIS QUESTION OUT?
            <br />
            yOU CANNOT UNDO THIS ACTION.
          </Heading4>
        </div>
      </Modal>
    </Container>
  );
};
