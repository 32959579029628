import uuidv4 from 'uuidv4';

import {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { PATH } from 'helpers/constant';
import { getNameRound } from 'helpers/gameplay';
import wsInstance from 'helpers/subscription-init';

import store, { useAppSelector } from 'store';
import {
  finishHostsAssignment,
  setIsBreakoutPopup,
  updateGameInfo,
  updateStoreGameEndStatus,
} from 'store/gameSlice';
import {
  EndGameStatus,
  GamePlaying,
  GameStatus,
  GameTeam,
  updateGamePlaying,
} from 'store/gameplaySlice';

import { CropImage } from 'components/CropImage';
import { ModalMessageContainer, useModal } from 'components/Modal';
import { BodyText, Heading4 } from 'components/Typography';
import { hostStatuses } from 'components/assign-hosts-room/AssignHostsRoom';
import { DEFAULT_TEAMPLAYERS_NUMBER } from 'components/edit-team-popup/EditTeam';
import {
  Tournament,
  revealWinnersResult,
  setPlayoffBracket,
} from 'components/playoff-bracket/playoffBracketSlice';

import { Config } from '../config';
import { HostItem } from 'api/default-settings';
import {
  createInitialTeamsForAdmin,
  fullHostInfo,
  updateTeamPhoto,
} from 'api/game';
import { getGameRoundInfo } from 'api/gameplay';

export const uploadTeamPhoto = () => {
  const { Modal, closeModal, openModal, open } = useModal();
  const [selectedHost, setSelectedHost] = useState<null | {
    type: 'delete' | 'update';
    host: HostItem;
  }>(null);

  const [selectedTeam, setSelectedTeam] = useState<null | {
    type: 'delete' | 'update';
    team: GameTeam;
  }>(null);

  const [originalImage, setOriginalImage] = useState<any>(null);

  const defaultInputs = {
    teamPhoto: '',
  };
  const [inputs, setInputs] = useState<{
    teamPhoto: any;
  }>(defaultInputs);
  const ref = useRef<any>(null);

  const onHandleSuccess = () => {
    closeModal();
  };

  const onSubmitHost = async () => {
    if (originalImage) {
      updateTeamPhoto(
        selectedTeam.team.id,
        {
          teamPhoto: originalImage,
        },
        onHandleSuccess,
      );
    }
    return;
  };

  const onEditTeamPhotoClick = (team: GameTeam) => (e: any) => {
    e.stopPropagation();
    setSelectedTeam({
      team,
      type: 'update',
    });
    const { teamPhoto } = team;
    setInputs({ teamPhoto });
    openModal();
  };

  const onDeleteHostClick = (host: HostItem) => (e: any) => {
    e.stopPropagation();
    setSelectedHost({
      host,
      type: 'delete',
    });
    openModal();
  };

  const renderTeamPhotoModal = useCallback(() => {
    const isDeleted = selectedHost?.type === 'delete';
    const { teamPhoto } = inputs;
    const orignalTeam = selectedTeam?.team;
    const disabledSubmit = !teamPhoto || teamPhoto === orignalTeam?.teamPhoto;

    return (
      <Modal
        style={{
          content: {
            width: selectedHost?.type === 'delete' ? 400 : 720,
          },
        }}
        header={
          isDeleted
            ? 'Delete host?'
            : selectedHost?.type === 'update'
            ? 'Edit host'
            : 'Add Team Photo'
        }
        footerStyle={{
          justifyContent: isDeleted ? 'space-between' : 'flex-end',
        }}
        submitAction={{
          title: selectedHost?.type || 'Add',
          onSubmit: onSubmitHost,
          buttonColor: isDeleted ? 'danger' : 'success',
          disabled: isDeleted ? false : disabledSubmit,
        }}
        onAfterClose={() => {
          setSelectedHost(null);
          setInputs(defaultInputs);
        }}
        submitOnEnter
      >
        {isDeleted ? (
          <ModalMessageContainer>
            <Heading4>Are you sure to delete this Host?</Heading4>
            <Heading4>This Action Cannot Be Undone.</Heading4>
          </ModalMessageContainer>
        ) : (
          <div>
            <BodyText color='white-60'>
              Recommended Size: 1920px x 1080px
            </BodyText>
            <CropImage
              url={
                selectedTeam?.team.teamPhoto
                  ? `${Config.REACT_APP_LAUNCHPAD_BASE_URL}storage/files/${selectedTeam?.team.teamPhoto}`
                  : null
              }
              ref={ref}
              onChangeFile={(url) => {
                setInputs({
                  ...inputs,
                  teamPhoto: url,
                });
              }}
              cropHeight={360}
              cropWidth={640}
              avatarHeight={360}
              avatarWidth={640}
              setOriginalImage={setOriginalImage}
            />
          </div>
        )}
      </Modal>
    );
  }, [open, selectedHost, inputs]);

  return {
    renderTeamPhotoModal,
    openTeamPhotoModal: openModal,
    onEditTeamPhotoClick,
    onDeleteHostClick,
  };
};

const modifyRoomData = (rawRoomData: any): GamePlaying => ({
  ...rawRoomData,
  roundName: getNameRound(rawRoomData.round, rawRoomData.game.teamNumber),
  game: {
    ...rawRoomData.game,
    customer: {
      contactPerson: rawRoomData.game.contactPerson,
      partner: rawRoomData.game.source?.name || '',
      company: rawRoomData.game.company,
    },
    gameTimer: rawRoomData.game.gameInformationFeud.totalGameTimer,
    breakoutTimer: rawRoomData.game.gameInformationFeud.breakoutRoomTimer,
    answerTimer: rawRoomData.game.gameInformationFeud.answerTimer,
    pointsMultiplier: rawRoomData.game.gameInformationFeud.multiplier,
    theme: rawRoomData.game.gameInformationFeud.theme,
    gameType: rawRoomData.game.gameOld.gameType,
    expireTime: rawRoomData.game.gameOld.expireTime,
    code: rawRoomData.game.gameOld.code,
    statusEnd: rawRoomData.game.gameOld.statusEnd,
    notStarted: rawRoomData.game.gameOld.notStarted,
    isBreakout: rawRoomData.game.gameOld.isBreakout,
    isHostsAssigned: rawRoomData.game.gameOld.isHostsAssigned,
    isBreakoutPopup: rawRoomData.game.gameOld.isBreakoutPopup,
    questionsTotal: rawRoomData.game.gameOld.questionsTotal,
    questionsPerMatchup: rawRoomData.game.gameInformationFeud.totalQuestions,
  },
});

export const useGetTournamentData = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const bracket = useAppSelector((store) => store.playoffBracket.bracket);
  const [matches, setMatches] = useState<GamePlaying[]>([]);
  const [teamPhotos, setTeamPhotos] = useState<string[] | null[]>([]);
  const [teamPhotoMissing, setTeamPhotoMissing] = useState<boolean>(true);

  const wsClient = useMemo(() => wsInstance.getInstance(), []);

  useEffect(() => {
    const teamPhotoMissing = teamPhotos.includes(null);
    setTeamPhotoMissing(teamPhotoMissing);
  }, [teamPhotos]);

  useEffect(() => {
    const subscribeToMatchups = async () => {
      const matchupsSubscription = wsClient.iterate({
        operationName: 'MySubscription',
        query: `subscription MySubscription ($eventId: uuid!) {
          event: events_by_pk(id: $eventId) {
            gameOld {
              status_end
            }
            matchups(order_by: {round: desc}) {
              id
              game_id
              game {
                id
                source {
                  id
                  name
                }
                gameInformationFeud {
                  id
                  totalGameTimer
                  breakoutRoomTimer
                  answerTimer
                  multiplier
                  collection {
                    id
                    name
                    type
                  }
                  totalQuestions
                  theme {
                    id
                    name
                    value: image_url
                  }
                  state
                  matchupSettings {
                    id
                    teams
                    questions
                    gameType
                    defaultQuestions
                  }
                }
                gameOld {
                  id
                  gameType: game_type
                  expireTime: expire_time
                  statusEnd: status_end
                  notStarted: not_started
                  isBreakout: is_breakout
                  isHostsAssigned: is_hosts_assigned
                  isBreakoutPopup: is_breakout_popup
                  code
                  questionsTotal: questions_total
                  questionsPerMatchup: questions_per_matchup
                }
                startTime: date
                timeZone: timezone
                status
                teamNumber: totalTeams
                company
                contactPerson
                hosts {
                  id
                  role
                  status
                  eventId
                  userId
                  user {
                    id
                    email
                    firstName
                    lastName
                    role
                  }
                }
                codes {
                  id
                  code
                  role
                }
                type: gameType
              }
              host {
                id
                email
                firstName
                lastName
                role
              }
              coHost {
                id
                email
                firstName
                lastName
                role
              }
              index
              isConsolation
              isStart
              isRevealed: is_revealed
              pointA
              pointB
              round
              rounds (order_by: { round: asc}) {
                id
                stackPoint: stack_point
                status
              }
              teamA {
                id
                hostId
                coHostId
                createdAt
                index
                name
                ranking
                teamPhoto: imageId
                totalScore
                members: players (order_by: {index: asc}) {
                  id
                  name
                  createdAt
                  index
                }
              }
              teamB {
                id
                hostId
                coHostId
                createdAt
                index
                name
                ranking
                teamPhoto: imageId
                totalScore
                members: players (order_by: {index: asc}) {
                  id
                  name
                  createdAt
                }
              }
              winner: winnerInfo {
                id
                name
                index
              }
              createdAt: created_at
              updatedAt: updated_at
            }
          }
        }`,
        variables: {
          eventId: id,
        },
      });

      if (matchupsSubscription) {
        for await (const res of matchupsSubscription) {
          const teamPlayingPhotos: string[] = [];
          const allRooms: Array<GamePlaying> = (
            res.data as any
          ).event.matchups.map((room: any) => modifyRoomData(room));

          const statusEnd = (res.data as any).event.gameOld.status_end;

          if (statusEnd !== EndGameStatus.none) {
            navigate(PATH.congratulations);
          }

          let matchups: Array<GamePlaying> = [];
          const tournament: Array<Tournament> = [];
          let revealedMatchups: Array<number> = [];

          if (allRooms.length) {
            // for matchups aside menu
            const maxRound: number =
              allRooms.filter(
                (room) => room.teamA && room.teamB && room.isStart,
              )?.[0]?.round || 0;
            matchups = allRooms
              .filter((room: GamePlaying) => room.round === maxRound)
              .sort(
                (roomA: GamePlaying, roomB: GamePlaying) =>
                  roomA.index - roomB.index,
              );

            matchups.forEach((room) => {
              teamPlayingPhotos.push(room.teamA?.teamPhoto);
              teamPlayingPhotos.push(room.teamB?.teamPhoto);
            });
            // for game map
            const gameMap: Array<GamePlaying> = allRooms.sort(
              (a: GamePlaying, b: GamePlaying) => a.index - b.index,
            );

            gameMap.forEach((room: GamePlaying) => {
              const teams = [];
              const isHosting = false;
              if (room.teamA) {
                teams.push({
                  matchId: room.teamA.index,
                  teamName: room.teamA.name,
                  point: room.pointA,
                });
              } else {
                teams.push({
                  matchId: 0,
                  teamName: '',
                  point: 0,
                });
              }
              if (room.teamB) {
                teams.push({
                  matchId: room.teamB.index,
                  teamName: room.teamB.name,
                  point: room.pointB,
                });
              } else {
                teams.push({
                  matchId: 0,
                  teamName: '',
                  point: 0,
                });
              }
              tournament.push({
                roomIndex: room.index,
                teams,
                room,
                isHosting,
                isNextMatchup: room.isStart,
                isStart: 2,
              });
            });

            revealedMatchups = allRooms
              .filter((room) => room.isRevealed)
              .map((room) => room.index);
          }

          // for matchups aside menu
          setTeamPhotos([...teamPlayingPhotos]);
          setMatches(matchups);

          //form game map
          store.dispatch(setPlayoffBracket(tournament));
          store.dispatch(revealWinnersResult(revealedMatchups));
          const newGameStatus = tournament?.[0]?.room?.game?.status;
          if (newGameStatus) {
            store.dispatch(updateGameInfo({ status: newGameStatus }));
          }
        }
      }
    };

    subscribeToMatchups();

    return () => {
      wsClient.terminate();
    };
  }, []);

  return {
    matches,
    teamPhotoMissing,
    bracket,
  };
};

export const useGetMainStageData = (
  isGameInfoLoaded: boolean,
  roomId: string,
  setLoaded: React.Dispatch<SetStateAction<boolean>>,
) => {
  const navigate = useNavigate();
  const location = useLocation();
  const hostId = (location.state as any)?.host;

  const wsClient = useMemo(() => wsInstance.getInstance(), []);

  useEffect(() => {
    if (isGameInfoLoaded && hostId) {
      const subscribeToMatchup = async () => {
        const matchupSubscription = wsClient.iterate({
          operationName: 'MySubscription',
          query: `subscription MySubscription ($roomId: Int!) {
            matchup: feud_old_game_rooms_by_pk(id: $roomId) {
              id
              game_id
              game {
                id
                source {
                  id
                  name
                }
                gameInformationFeud {
                  id
                  totalGameTimer
                  breakoutRoomTimer
                  answerTimer
                  multiplier
                  collection {
                    id
                    name
                    type
                  }
                  totalQuestions
                  theme {
                    id
                    name
                    value: image_url
                  }
                  state
                  matchupSettings {
                    id
                    teams
                    questions
                    gameType
                    defaultQuestions
                  }
                }
                gameOld {
                  id
                  gameType: game_type
                  expireTime: expire_time
                  statusEnd: status_end
                  notStarted: not_started
                  isBreakout: is_breakout
                  isHostsAssigned: is_hosts_assigned
                  isBreakoutPopup: is_breakout_popup
                  code
                  questionsTotal: questions_total
                  questionsPerMatchup: questions_per_matchup
                }
                startTime: date
                timeZone: timezone
                status
                teamNumber: totalTeams
                company
                contactPerson
                hosts {
                  id
                  role
                  status
                  eventId
                  userId
                  user {
                    id
                    email
                    firstName
                    lastName
                    role
                  }
                }
                codes {
                  id
                  code
                  role
                }
                type: gameType
              }
              host {
                id
                email
                firstName
                lastName
                role
              }
              coHost {
                id
                email
                firstName
                lastName
                role
              }
              index
              isConsolation
              isStart
              pointA
              pointB
              round
              rounds (order_by: { round: asc}) {
                id
                stackPoint: stack_point
                status
                question {
                  id
                  content: question
                  answers (order_by: { points: desc }) {
                    id
                    answer
                    point: points
                  }
                }
              }
              teamA {
                id
                hostId
                coHostId
                createdAt
                index
                name
                ranking
                teamPhoto: imageId
                totalScore
                members: players (order_by: {index: asc}) {
                  id
                  name
                  createdAt
                  index
                }
              }
              teamB {
                id
                hostId
                coHostId
                createdAt
                index
                name
                ranking
                teamPhoto: imageId
                totalScore
                members: players (order_by: {index: asc}) {
                  id
                  name
                  createdAt
                }
              }
              winner: winnerInfo {
                id
                name
                index
              }
              createdAt: created_at
              updatedAt: updated_at
            }
          }`,
          variables: {
            roomId,
          },
        });

        if (matchupSubscription) {
          for await (const res of matchupSubscription) {
            const rawRoomData: any = (res.data as any).matchup;
            if (rawRoomData.rounds?.length) {
              return navigate(`/game/gameplay/${roomId}`, {
                state: {
                  host: hostId,
                },
              });
            }
            const roomData = modifyRoomData(rawRoomData);
            store.dispatch(updateGamePlaying(roomData));
            setLoaded(true);
          }
        }
      };

      subscribeToMatchup();

      return () => {
        wsClient.terminate();
      };
    }
  }, [isGameInfoLoaded]);
};

export const useGetGamePlayData = (
  isGameInfoLoaded: boolean,
  roomId: string,
  gameId: string,
) => {
  const location = useLocation();
  const navigate = useNavigate();
  const hostId = (location.state as any)?.host;

  const wsClient = useMemo(() => wsInstance.getInstance(), []);

  useEffect(() => {
    if (isGameInfoLoaded && hostId) {
      const subscribeToMatchup = async () => {
        const matchupSubscription = wsClient.iterate({
          operationName: 'MySubscription',
          query: `subscription MySubscription ($roomId: Int!) {
            matchup: feud_old_game_rooms_by_pk(id: $roomId) {
              id
              game_id
              game {
                id
                source {
                  id
                  name
                }
                gameInformationFeud {
                  id
                  totalGameTimer
                  breakoutRoomTimer
                  answerTimer
                  multiplier
                  collection {
                    id
                    name
                    type
                  }
                  totalQuestions
                  theme {
                    id
                    name
                    value: image_url
                  }
                  state
                  matchupSettings {
                    id
                    teams
                    questions
                    gameType
                    defaultQuestions
                  }
                }
                gameOld {
                  id
                  gameType: game_type
                  expireTime: expire_time
                  statusEnd: status_end
                  notStarted: not_started
                  isBreakout: is_breakout
                  isHostsAssigned: is_hosts_assigned
                  isBreakoutPopup: is_breakout_popup
                  code
                  questionsTotal: questions_total
                  questionsPerMatchup: questions_per_matchup
                }
                startTime: date
                timeZone: timezone
                status
                teamNumber: totalTeams
                company
                contactPerson
                hosts {
                  id
                  role
                  status
                  eventId
                  userId
                  user {
                    id
                    email
                    firstName
                    lastName
                    role
                  }
                }
                codes {
                  id
                  code
                  role
                }
                type: gameType
              }
              host {
                id
                email
                firstName
                lastName
                role
              }
              coHost {
                id
                email
                firstName
                lastName
                role
              }
              index
              isConsolation
              isStart
              pointA
              pointB
              round
              rounds (order_by: { round: asc}) {
                id
                stackPoint: stack_point
                status
                strikes
                question {
                  id
                  content: question
                  answers (order_by: { points: desc }) {
                    id
                    answer
                    point: points
                  }
                }
              }
              teamA {
                id
                hostId
                coHostId
                createdAt
                index
                name
                ranking
                teamPhoto: imageId
                totalScore
                members: players (order_by: {index: asc}) {
                  id
                  name
                  createdAt
                  index
                }
              }
              teamB {
                id
                hostId
                coHostId
                createdAt
                index
                name
                ranking
                teamPhoto: imageId
                totalScore
                members: players (order_by: {index: asc}) {
                  id
                  name
                  createdAt
                }
              }
              winner: winnerInfo {
                id
                name
                index
              }
              createdAt: created_at
              updatedAt: updated_at
            }
          }`,
          variables: {
            roomId,
          },
        });

        if (matchupSubscription) {
          for await (const res of matchupSubscription) {
            const rawRoomData: any = (res.data as any).matchup;
            const roomData = modifyRoomData(rawRoomData);
            if (roomData.winner) return navigate(`/game/${gameId}/panel`);
            store.dispatch(updateGamePlaying(roomData));
            getGameRoundInfo();
          }
        }
      };

      subscribeToMatchup();

      return () => {
        wsClient.terminate();
      };
    }
  }, [isGameInfoLoaded]);
};

export const useGetAssignHostsData = (id: string, teamNumber: number) => {
  const [rooms, setRooms] = useState<any[]>([]);
  const [hosts, setHosts] = useState<Array<fullHostInfo>>([]);
  const wsClient = useMemo(() => wsInstance.getInstance(), []);

  useEffect(() => {
    if (id) {
      const subscribeToRooms = async () => {
        const roomsSubscription = wsClient.iterate({
          operationName: 'MySubscription',
          query: `subscription MySubscription ($eventId: uuid!) {
            event: events_by_pk (id: $eventId) {
              gameOld {
                is_hosts_assigned
              }
              hosts {
                id
                eventId
                userId
                status
                role
                host: user {
                  id
                  email
                  firstName
                  lastName
                  role
                }
              }
              matchups(order_by: { index: asc }) {
                id
                game_id
                game {
                  id
                  source {
                    id
                    name
                  }
                  gameInformationFeud {
                    id
                    totalGameTimer
                    breakoutRoomTimer
                    answerTimer
                    multiplier
                    collection {
                      id
                      name
                      type
                    }
                    totalQuestions
                    theme {
                      id
                      name
                      value: image_url
                    }
                    state
                    matchupSettings {
                      id
                      teams
                      questions
                      gameType
                      defaultQuestions
                    }
                  }
                  gameOld {
                    id
                    gameType: game_type
                    expireTime: expire_time
                    statusEnd: status_end
                    notStarted: not_started
                    isBreakout: is_breakout
                    isHostsAssigned: is_hosts_assigned
                    isBreakoutPopup: is_breakout_popup
                    code
                    questionsTotal: questions_total
                    questionsPerMatchup: questions_per_matchup
                  }
                  startTime: date
                  timeZone: timezone
                  status
                  teamNumber: totalTeams
                  company
                  contactPerson
                  hosts {
                    id
                    role
                    status
                    eventId
                    userId
                    user {
                      id
                      email
                      firstName
                      lastName
                      role
                    }
                  }
                  codes {
                    id
                    code
                    role
                  }
                  type: gameType
                }
                host {
                  id
                  email
                  firstName
                  lastName
                  role
                }
                coHost {
                  id
                  email
                  firstName
                  lastName
                  role
                }
                index
                isConsolation
                isStart
                pointA
                pointB
                round
                rounds (order_by: { round: asc}) {
                  id
                  stackPoint: stack_point
                  status
                  question {
                    id
                    content: question
                    answers (order_by: { points: desc }) {
                      id
                      answer
                      point: points
                    }
                  }
                }
                teamA {
                  id
                  hostId
                  coHostId
                  createdAt
                  index
                  name
                  ranking
                  teamPhoto: imageId
                  totalScore
                  members: players (order_by: {index: asc}) {
                    id
                    name
                    createdAt
                    index
                  }
                }
                teamB {
                  id
                  hostId
                  coHostId
                  createdAt
                  index
                  name
                  ranking
                  teamPhoto: imageId
                  totalScore
                  members: players (order_by: {index: asc}) {
                    id
                    name
                    createdAt
                  }
                }
                winner: winnerInfo {
                  id
                  name
                  index
                }
                createdAt: created_at
                updatedAt: updated_at
              }
            }
          }`,
          variables: {
            eventId: id,
          },
        });

        if (roomsSubscription) {
          for await (const res of roomsSubscription) {
            const {
              event: { matchups: fetchedRooms, gameOld, hosts },
            } = res.data as any;

            const isHostsAssigned = gameOld.is_hosts_assigned;
            if (isHostsAssigned) {
              store.dispatch(finishHostsAssignment());
              return;
            }

            if (!fetchedRooms.length) {
              // create initial teams and rooms
              const newTeams = Array.from({ length: teamNumber }, (_, i) => ({
                id: uuidv4(),
                index: i,
                name: `Team ${i + 1}`,
              }));

              createInitialTeamsForAdmin(id, newTeams);
            }
            setRooms(fetchedRooms);
            setHosts(
              hosts.filter(
                (host: any) => host.status !== hostStatuses.SCHEDULED,
              ),
            );
          }
        }
      };

      subscribeToRooms();

      return () => {
        wsClient.terminate();
      };
    }
  }, []);

  return { hosts, rooms, setRooms };
};

export const createEmptyPlayers = (members: Array<any>): Array<any> => {
  let lastIndex = [...members].reduce(
    (res, curr) => (curr.index < res ? res : curr.index),
    -1,
  );

  const result: Array<any> = [];
  for (let i = 0; i < DEFAULT_TEAMPLAYERS_NUMBER; i++) {
    result.push({
      id: uuidv4(),
      index: ++lastIndex,
      name: '',
      isMock: true,
    });
  }
  return result;
};

export const useGetBreakoutData = (id: string, teamNumber: number) => {
  const [teams, setTeams] = useState<any[]>([]);
  const originalTeams = useRef<any[]>([]);
  const wsClient = useMemo(() => wsInstance.getInstance(), []);

  useEffect(() => {
    if (id) {
      const subscribeToTeams = async () => {
        const teamsSubscription = wsClient.iterate({
          operationName: 'MySubscription',
          query: `subscription MySubscription ($eventId: uuid!) {
            teams (where: { eventId: { _eq: $eventId }}, order_by: { index: asc }) {
              id
              index
              name
              ranking
              teamPhoto: imageId
              totalScore
              createdAt
              game: event {
                id
                type: gameType
                createdAt
                updatedAt
                company
                contactPerson
                gameOld {
                  id
                  code
                  expireTime: expire_time
                  gameType: game_type
                  isBreakout: is_breakout
                  isBreakoutPopup: is_breakout_popup
                  isHostsAssigned: is_hosts_assigned
                  notStarted: not_started
                  questionsPerMatchup: questions_per_matchup
                  questionsTotal: questions_total
                  statusEnd: status_end
                }
                startTime: date
                status
                teamNumber: totalTeams
                timeZone: timezone
              }
              hostId
              gameHost: host {
                id
                email
                firstName
                lastName
                role
              }
              coHostId
              members: players (order_by: { index: asc }) {
                id
                index
                name
                createdAt
              }
            }
          }`,
          variables: {
            eventId: id,
          },
        });

        if (teamsSubscription) {
          let isAlreadyCloseBreakout = false;

          for await (const res of teamsSubscription) {
            const fetchedTeams: Array<any> = (res.data as any).teams;

            const teamsList: any = [];
            Array.from({ length: teamNumber }, (_, i) => i).forEach((index) => {
              const existed = fetchedTeams.find((team) => team.index === index);

              teamsList[index] = existed
                ? {
                    ...existed,
                    name: existed.name || `Team ${index + 1}`,
                    members: [
                      ...existed.members,
                      ...createEmptyPlayers(existed.members).slice(
                        0,
                        DEFAULT_TEAMPLAYERS_NUMBER - existed.members.length,
                      ),
                    ],
                  }
                : {
                    index,
                    name: `Team ${index + 1}`,
                    teamPhoto: null,
                    members: [...createEmptyPlayers([])],
                  };
            });

            originalTeams.current = teamsList;
            setTeams(teamsList);

            if (
              fetchedTeams?.[0].game?.gameOld?.isBreakoutPopup &&
              !isAlreadyCloseBreakout
            ) {
              store.dispatch(setIsBreakoutPopup());
              isAlreadyCloseBreakout = true;
            }

            if (fetchedTeams?.[0]?.game?.status === GameStatus.playing)
              store.dispatch(updateGameInfo({ status: GameStatus.playing }));
          }
        }
      };

      subscribeToTeams();

      return () => {
        wsClient.terminate();
      };
    }
  }, []);

  return { teams, setTeams };
};

export const useGetHostsTournamentData = () => {
  const navigate = useNavigate();
  const currentUserId = useAppSelector((state) => state.game.currentUserId);
  const bracket = useAppSelector((store) => store.playoffBracket.bracket);
  const id = useAppSelector((store) => store.game.game.id);
  const wsClient = useMemo(() => wsInstance.getInstance(), []);

  useEffect(() => {
    const subscribeToMatchups = async () => {
      const matchupsSubscription = wsClient.iterate({
        operationName: 'MySubscription',
        query: `subscription MySubscription ($eventId: uuid!) {
            event: events_by_pk(id: $eventId) {
              gameOld {
                status_end
              }
              matchups(order_by: {round: desc}) {
                id
                game_id
                game {
                  id
                  source {
                    id
                    name
                  }
                  gameInformationFeud {
                    id
                    totalGameTimer
                    breakoutRoomTimer
                    answerTimer
                    multiplier
                    collection {
                      id
                      name
                      type
                    }
                    totalQuestions
                    theme {
                      id
                      name
                      value: image_url
                    }
                    state
                    matchupSettings {
                      id
                      teams
                      questions
                      gameType
                      defaultQuestions
                    }
                  }
                  gameOld {
                    id
                    gameType: game_type
                    expireTime: expire_time
                    statusEnd: status_end
                    notStarted: not_started
                    isBreakout: is_breakout
                    isHostsAssigned: is_hosts_assigned
                    isBreakoutPopup: is_breakout_popup
                    code
                    questionsTotal: questions_total
                    questionsPerMatchup: questions_per_matchup
                  }
                  startTime: date
                  timeZone: timezone
                  status
                  teamNumber: totalTeams
                  company
                  contactPerson
                  hosts {
                    id
                    role
                    status
                    eventId
                    userId
                    user {
                      id
                      email
                      firstName
                      lastName
                      role
                    }
                  }
                  codes {
                    id
                    code
                    role
                  }
                  type: gameType
                }
                host {
                  id
                  email
                  firstName
                  lastName
                  role
                }
                coHost {
                  id
                  email
                  firstName
                  lastName
                  role
                }
                index
                isConsolation
                isStart
                isRevealed: is_revealed
                pointA
                pointB
                round
                rounds (order_by: { round: asc}) {
                  id
                  stackPoint: stack_point
                  status
                }
                teamA {
                  id
                  hostId
                  coHostId
                  createdAt
                  index
                  name
                  ranking
                  teamPhoto: imageId
                  totalScore
                  members: players (order_by: {index: asc}) {
                    id
                    name
                    createdAt
                    index
                  }
                }
                teamB {
                  id
                  hostId
                  coHostId
                  createdAt
                  index
                  name
                  ranking
                  teamPhoto: imageId
                  totalScore
                  members: players (order_by: {index: asc}) {
                    id
                    name
                    createdAt
                  }
                }
                winner: winnerInfo {
                  id
                  name
                  index
                }
                createdAt: created_at
                updatedAt: updated_at
              }
            }
          }`,
        variables: {
          eventId: id,
        },
      });

      if (matchupsSubscription) {
        for await (const res of matchupsSubscription) {
          const teamPlayingPhotos: string[] = [];
          const allRooms: Array<GamePlaying> = (
            res.data as any
          ).event.matchups.map((room: any) => modifyRoomData(room));

          const statusEnd = (res.data as any).event.gameOld.status_end;

          if (statusEnd !== EndGameStatus.none) {
            navigate(PATH.congratulations);
          }

          let matchups: Array<GamePlaying> = [];
          const tournament: Array<Tournament> = [];
          let revealedMatchups: Array<number> = [];

          if (allRooms.length) {
            // for matchups aside menu
            const maxRound: number =
              allRooms.filter(
                (room) => room.teamA && room.teamB && room.isStart,
              )?.[0]?.round || 0;
            matchups = allRooms
              .filter((room: GamePlaying) => room.round === maxRound)
              .sort(
                (roomA: GamePlaying, roomB: GamePlaying) =>
                  roomA.index - roomB.index,
              );

            matchups.forEach((room) => {
              teamPlayingPhotos.push(room.teamA?.teamPhoto);
              teamPlayingPhotos.push(room.teamB?.teamPhoto);
            });
            // for game map
            const gameMap: Array<GamePlaying> = allRooms.sort(
              (a: GamePlaying, b: GamePlaying) => a.index - b.index,
            );

            gameMap.forEach((room: GamePlaying) => {
              const teams = [];
              const isHosting = false;
              if (room.teamA) {
                teams.push({
                  matchId: room.teamA.index,
                  teamName: room.teamA.name,
                  point: room.pointA,
                });
              } else {
                teams.push({
                  matchId: 0,
                  teamName: '',
                  point: 0,
                });
              }
              if (room.teamB) {
                teams.push({
                  matchId: room.teamB.index,
                  teamName: room.teamB.name,
                  point: room.pointB,
                });
              } else {
                teams.push({
                  matchId: 0,
                  teamName: '',
                  point: 0,
                });
              }
              tournament.push({
                roomIndex: room.index,
                teams,
                room,
                isHosting,
                isNextMatchup: room.isStart,
                isStart: 2,
              });
            });

            revealedMatchups = allRooms
              .filter((room) => room.isRevealed)
              .map((room) => room.index);
          }

          //form game map
          store.dispatch(setPlayoffBracket(tournament));
          store.dispatch(revealWinnersResult(revealedMatchups));

          const runningGameForThisUser = tournament.find(
            ({ room }) =>
              room &&
              !room.winner &&
              room.isStart &&
              currentUserId &&
              (currentUserId === room.host?.id ||
                currentUserId === room.coHost?.id),
          );

          runningGameForThisUser &&
            navigate(`/game/main-stage/${runningGameForThisUser.room.id}`);
        }
      }
    };

    subscribeToMatchups();

    return () => {
      wsClient.terminate();
    };
  }, []);

  return {
    bracket,
  };
};

export const useGetScoreSummaryWaitingData = (id: string) => {
  const wsClient = useMemo(() => wsInstance.getInstance(), []);
  const [isAllCurrentMatchupsFinished, setIsAllCurrentMatchupsFinished] =
    useState(false);
  const userId = useAppSelector((store) => store.game.currentUserId);

  useEffect(() => {
    if (id) {
      const subscribeToRooms = async () => {
        const roomsSubscription = wsClient.iterate({
          operationName: 'MySubscription',
          query: `subscription MySubscription ($eventId: uuid!) {
            rooms: feud_old_game_rooms(where: {_and: [{game_id: {_eq: $eventId}}, {isStart: {_neq: 0}}]}, order_by: { index: desc }) {
              round
              winner
              host {
                id
              }
              game {
                totalTeams
              }
            }
          }`,
          variables: {
            eventId: id,
          },
        });

        if (roomsSubscription) {
          for await (const res of roomsSubscription) {
            const fetchedRooms = res.data.rooms as any;
            if (!fetchedRooms.length) {
              setIsAllCurrentMatchupsFinished(false);
            } else {
              const {
                round: maxRound,
                game: { totalTeams },
              } = fetchedRooms[0];
              const matchups = totalTeams / 2;

              const isExistMatchupForThisUser = fetchedRooms.find(
                (room: any) => !room.winner && room.host?.id === userId,
              );

              const currentMatchups = fetchedRooms.filter(
                (room: any) => room.round === maxRound && !!room.winner,
              );

              setIsAllCurrentMatchupsFinished(
                isExistMatchupForThisUser || // if user come on waiting screen by accident and some room for this host is already created and started by game leader or
                  currentMatchups.length === matchups, // if all current matchups are already finished
              );
            }
          }
        }
      };

      subscribeToRooms();

      return () => {
        wsClient.terminate();
      };
    }
  }, []);

  return isAllCurrentMatchupsFinished;
};

export const useGetTeamWaitingData = (roomId: string) => {
  const wsClient = useMemo(() => wsInstance.getInstance(), []);
  const [gameTeam, setGameTeam] = useState<GameTeam[]>([]);

  useEffect(() => {
    if (roomId) {
      const subscribeToRoom = async () => {
        const roomsSubscription = wsClient.iterate({
          operationName: 'MySubscription',
          query: `subscription MySubscription ($roomId: Int!) {
            room: feud_old_game_rooms_by_pk(id: $roomId) {
              teamA {
                id
                index
                name
                members: players(order_by: {index: asc}) {
                  id
                  name
                  index
                  createdAt
                }
              }
              teamB {
                id
                index
                name
                members: players(order_by: {index: asc}) {
                  id
                  name
                  index
                  createdAt
                }
              }
            }
          }`,
          variables: {
            roomId,
          },
        });

        if (roomsSubscription) {
          for await (const res of roomsSubscription) {
            const { teamA, teamB }: { teamA: GameTeam; teamB: GameTeam } = res
              .data.room as any;
            setGameTeam(teamA && teamB ? [teamA, teamB] : []);
          }
        }
      };

      subscribeToRoom();

      return () => {
        wsClient.terminate();
      };
    }
  }, []);

  return { gameTeam, setGameTeam };
};

export const useGetCongratulationsData = (eventId: string) => {
  const wsClient = useMemo(() => wsInstance.getInstance(), []);
  const [gameTeam, setGameTeam] = useState<GameTeam[]>([]);

  useEffect(() => {
    if (eventId) {
      const subscribeToEventStatus = async () => {
        const eventStatusSubscription = wsClient.iterate({
          operationName: 'MySubscription',
          query: `subscription MySubscription ($eventId: uuid!) {
            events_by_pk (id: $eventId) {
              gameOld {
                status_end
              }
            }
          }`,
          variables: {
            eventId,
          },
        });

        if (eventStatusSubscription) {
          for await (const res of eventStatusSubscription) {
            const statusEnd = (res.data.events_by_pk as any)?.gameOld
              ?.status_end;
            Number.isInteger(statusEnd) &&
              store.dispatch(updateStoreGameEndStatus(statusEnd));
          }
        }
      };

      subscribeToEventStatus();

      return () => {
        wsClient.terminate();
      };
    }
  }, []);

  return { gameTeam, setGameTeam };
};
