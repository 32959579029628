import { useCallback, useRef, useState } from 'react';

import { ReactComponent as ClipIcon } from 'assets/icons/clip-icon.svg';

import useOnClickOutside from 'hooks/useClickOutside';

import { RootState, useAppSelector } from 'store';
import { ResourceCategoryType } from 'store/resources';

import styled from 'styled-components';

import Category from './category/Category';

const Resources: React.FC = () => {
  const [isOpenResources, setIsOpenResources] = useState(false);
  const resources = useAppSelector(
    (state: RootState) => state.resources.resources,
  );

  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => {
    setIsOpenResources(false);
  });

  const handleToggleResources = useCallback(
    () => setIsOpenResources((prev) => !prev),
    [],
  );

  return (
    <ComponentWrap ref={ref}>
      <ResourcesBtn
        type='button'
        onClick={handleToggleResources}
        isOpen={isOpenResources}
      >
        <span>HOST RESOURCES</span>
        <ClipIcon />
      </ResourcesBtn>
      <HostResources isOpen={isOpenResources}>
        <HostResourcesList>
          {resources.map((category: ResourceCategoryType) => (
            <Category key={category.id} category={category} />
          ))}
        </HostResourcesList>
      </HostResources>
    </ComponentWrap>
  );
};

export default Resources;

const ComponentWrap = styled.div`
  position: relative;
  display: flex;
  font-family: 'fugaz-one';
`;

const ResourcesBtn = styled.button<{ isOpen: boolean }>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 0.25rem 1.25rem;
  gap: 0.5rem;
  border-radius: 0.75rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: ${({ isOpen }) => (isOpen ? '#071B50' : 'inherit')};
  cursor: pointer;
  font-family: inherit;

  & > span {
    color: #fff;
    font-size: 0.8125rem;
    font-style: normal;
    line-height: 1;
  }
`;

const HostResources = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  position: absolute;
  z-index: 2;
  width: 540px;
  max-height: 460px;
  overflow: hidden auto;
  right: 0;
  bottom: 0;
  transform: translateY(calc(100% + 0.5rem));
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.29);
  background: linear-gradient(180deg, #071b51 3.38%, #01030a 95.11%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 1rem;
`;

const HostResourcesList = styled.ul`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  list-style: none;
  gap: 2.25rem;
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0;
  box-sizing: content-box;
`;
