import { useCallback, useRef } from 'react';

import { ReactComponent as WarningIcon } from 'assets/icons/warning-circle.svg';

import { ContainedButton } from 'components/Button';
import { useModal } from 'components/Modal';
import { Heading4, Heading5 } from 'components/Typography';

export const useHostLogin = () => {
  const { Modal, closeModal, openModal, open } = useModal();
  const errorMessage = useRef<string>('');

  const renderHostLoginFailModal = useCallback(() => {
    return (
      <Modal
        style={{
          content: {
            width: 400,
          },
        }}
        header=''
        type='custom'
        submitOnEnter
      >
        <div style={{ textAlign: 'center', marginTop: 60, marginBottom: 80 }}>
          <WarningIcon />
          <Heading4 style={{ marginBottom: 10, marginTop: 15 }}>
            Login failed!
          </Heading4>
          <Heading5>{errorMessage.current}</Heading5>
        </div>
        <ContainedButton
          size='xl'
          onClick={closeModal}
          style={{ width: '100%', backgroundSize: '100% 100%' }}
        >
          OK
        </ContainedButton>
      </Modal>
    );
  }, [open]);

  const onOpenModal = (error: string) => {
    errorMessage.current = error;
    openModal();
  };

  return {
    renderHostLoginFailModal,
    openModal: onOpenModal,
  };
};
